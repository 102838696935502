import { useEffect, useRef } from 'react';

/** Hook that runs a callback only _once_ per page load.
 * Should be avoided if possible, but useful when usual dependencies to useEffect causes an effect to be triggered
 * more times than desirable.
 *
 * An example use case could be to send a "viewed" tracking event when loading the page, right after the user object is available.
 */
export function useOnce(shouldRun: boolean, callback: () => void) {
  const hasRun = useRef<boolean>(false);

  useEffect(() => {
    if (!hasRun.current && shouldRun) {
      callback();
      hasRun.current = true;
    }
  }, [callback, hasRun, shouldRun]);
}
