'use client';

import { useOnce } from '@mentimeter/react-hooks';
import { useRouter, useSearchParams } from '@mentimeter/next-navigation';
import { captureException, MentiError } from '@mentimeter/errors/edge';
import { trackUser } from '@api/tracking/client';
import { TrackingContext } from '@mentimeter/http-clients';
import { LocalStorage } from '@mentimeter/storage';
import { createPresentation } from './createPresentation';
import { getNewPresentationUrl } from './getNewPresentationUrl';

const EDITOR_ONBOARDING_KEY = 'mm-editor-onboarding';

interface OnboardingUserFlags {
  show_onboarding_modal?: boolean;
}

function setOnboardingFlag(show_onboarding_modal: boolean) {
  LocalStorage.setJSONItem<OnboardingUserFlags>({
    type: 'functional',
    key: EDITOR_ONBOARDING_KEY,
    value: {
      show_onboarding_modal,
    },
  });
}

enum QueryKeys {
  // Parameters that configure the settings of the new presentation:

  // Sets the title of the presentation
  SeriesTitle = 'series-title',

  // Cross-region compatible theme name
  PublicThemeName = 'public-theme-name',
  PublicThemeNameShort = 'th',

  // Deprecated, use ThemeName instead
  ThemeId = 'theme-id',

  // Sets the pace of the presentation
  Pace = 'pace',

  // Parameters that configure the first question in the new presentation:

  // Copies all properties of the question from the given Question ID
  CopyQuestionId = 'copy-question-id',

  // Sets the question title of the new question
  QuestionTitle = 'question-title',
  QuestionTitleShort = 'q',

  // Sets the question type of the new question
  QuestionType = 'question-type',
  QuestionTypeShort = 't',

  // Sets the sub type of the new question (pie, donut, dots, etc.)
  QuestionVisulisation = 'question-visualisation',
  QuestionVisulisationShort = 'v',

  // Shows the template mini modal for the provided filter when arriving in the editor (show on top of the slide selector if open)
  QuickCreate = 'quick-create',

  // Shows the template modal when arriving in the editor (show on top of the slide selector if open)
  TemplateModal = 'template-modal',

  // Shows the import modal when arriving in the editor (show on top of the slide selector if open)
  ImportModal = 'import-modal',

  // Show the onboarding modal when arriving in the editor
  Onboarding = 'onboarding',
}

export default function NewPage() {
  const router = useRouter();
  const searchParams = useSearchParams();

  const seriesTitle = searchParams.get(QueryKeys.SeriesTitle);
  const themeId = searchParams.get(QueryKeys.ThemeId);
  const publicThemeName =
    searchParams.get(QueryKeys.PublicThemeName) ||
    searchParams.get(QueryKeys.PublicThemeNameShort);
  const pace = searchParams.get(QueryKeys.Pace);
  const copyQuestionId = searchParams.get(QueryKeys.CopyQuestionId);
  const questionTitle =
    searchParams.get(QueryKeys.QuestionTitle) ||
    searchParams.get(QueryKeys.QuestionTitleShort) ||
    '';
  const questionType =
    searchParams.get(QueryKeys.QuestionType) ||
    searchParams.get(QueryKeys.QuestionTypeShort);
  const questionVisualisation =
    searchParams.get(QueryKeys.QuestionVisulisation) ||
    searchParams.get(QueryKeys.QuestionVisulisationShort);
  const templateModal = searchParams.get(QueryKeys.TemplateModal);
  const importModal = searchParams.get(QueryKeys.ImportModal);
  const quickCreate = searchParams.get(QueryKeys.QuickCreate);
  const showOnboardingModal = searchParams.has(QueryKeys.Onboarding);

  useOnce(true, () => {
    if (showOnboardingModal) {
      setOnboardingFlag(true);

      trackUser({
        event: 'Started onboarding journey',
        properties: {
          context: TrackingContext.Onboarding,
        },
      });
    }

    const run = async () => {
      try {
        const { data: presentation } = await createPresentation({
          seriesTitle,
          publicThemeName,
          themeId,
          pace,
          copyQuestionId,
          questionTitle,
          questionType,
          questionVisualisation,
        });
        const url = getNewPresentationUrl({
          presentation,
          templateModal,
          importModal,
          quickCreate,
          copyQuestionId,
          questionType,
        });
        router.replace(url);
      } catch (err) {
        captureException(
          new MentiError('Failed to create presentation via /new page', {
            feature: 'paid-user-growth',
            cause: err,
          }),
        );
        router.replace('/app');
      }
    };
    run();
  });

  return null;
}
