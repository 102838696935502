import type {
  PaceMode,
  Question,
  QuestionSubType,
  QuestionType,
  SeriesPost,
} from '@mentimeter/http-clients';
import { core } from '@mentimeter/http-clients';
import { captureException, MentiError } from '@mentimeter/errors/edge';
import { DEFAULT_SERIES_REACTIONS } from 'lib/default-series-reactions';

const VALID_QUESTION_TYPES: Readonly<QuestionType[]> = [
  'choices',
  'open',
  'choices_images',
  'slide',
  'metadata',
  'pin_on_image',
  'prioritisation',
  'qfa',
  'quiz_open',
  'quiz',
  'ranking',
  'rating',
  'scales',
  'wordcloud',
] as const;

const QUESTION_TYPES_WITH_DEFAULT_CHOICES: Readonly<QuestionType[]> = [
  'quiz',
  'choices',
] as const;

const VALID_VISUALISATIONS_BY_QUESTION_TYPE: Record<string, QuestionSubType[]> =
  {
    choices: ['choices', 'pie', 'donut', 'dots'],
  };

const VALID_VISUALISATIONS = Object.values(
  VALID_VISUALISATIONS_BY_QUESTION_TYPE,
).flat();

interface NewParams {
  seriesTitle?: string | undefined | null;
  publicThemeName?: string | undefined | null;
  /**
   * @deprecated Use publicThemeName instead
   */
  themeId?: string | undefined | null;
  pace?: string | undefined | null;
  copyQuestionId?: string | undefined | null;
  questionTitle?: string | undefined | null;
  questionType?: string | undefined | null;
  questionVisualisation?: string | undefined | null;
  templateModal?: string | undefined | null;
  importModal?: string | undefined | null;
  quickCreate?: string | undefined | null;
}

export async function createPresentation({
  seriesTitle,
  publicThemeName,
  themeId,
  pace,
  copyQuestionId,
  questionTitle,
  questionType,
  questionVisualisation,
}: NewParams) {
  const seriesParameters: SeriesPost = {
    name: 'Untitled presentation',
    reactions: DEFAULT_SERIES_REACTIONS,
  };

  const questionParameters: Partial<Question> = {};

  const specifiedQuestionType = getValidQuestionType(questionType);
  const validQuestionVisualisation = getValidVisualisation(
    questionVisualisation,
    questionType,
  );
  const validThemeId = getValidThemeId(themeId);
  const validPace = getValidPace(pace);

  if (seriesTitle) {
    seriesParameters.name = seriesTitle;
  }

  if (validPace) {
    seriesParameters.pace = validPace;
  }

  // Public theme name takes precedence over theme id but keeps it for backwards compatibility
  if (publicThemeName) {
    seriesParameters.public_theme_name = publicThemeName;
  } else if (validThemeId !== null) {
    seriesParameters.theme_id = validThemeId;
  }

  if (copyQuestionId) {
    try {
      const { data: questionToCopy } = await core({
        /* 
        The template administration account 'alla+content' exist in EU.
        Therefore it's important that we always fetch the question from the EU.
        It's also important that template admins only create template questions on the 'alla+content' account. 
        */
        region: 'eu',
      }).questions.get(copyQuestionId);
      Object.assign(questionParameters, questionToCopy);
    } catch (err) {
      captureException(
        new MentiError(
          'Failed to copy question when creating presentation via /new page',
          {
            feature: 'paid-user-growth',
            cause: err,
          },
        ),
      );
    }
  }

  if (questionTitle) {
    questionParameters.question = questionTitle;
  }

  if (validQuestionVisualisation) {
    questionParameters.sub_type = validQuestionVisualisation;
  }

  const isQuiz =
    specifiedQuestionType === 'quiz' || specifiedQuestionType === 'quiz_open';

  if (isQuiz) {
    seriesParameters.questions = [
      { type: specifiedQuestionType },
      { type: 'quiz_leaderboard' },
    ];
  } else {
    if (specifiedQuestionType) {
      questionParameters.type = specifiedQuestionType;
    }
  }

  const hasSpecifiedQuestionParamters =
    Object.keys(questionParameters).length > 0;

  if (hasSpecifiedQuestionParamters) {
    seriesParameters.questions = seriesParameters.questions ?? [];
    const firstQuestion = seriesParameters.questions[0] ?? {};
    Object.assign(firstQuestion, questionParameters);
    seriesParameters.questions[0] = firstQuestion;
  }

  const slideTypeApplicableForDefaultChoices =
    specifiedQuestionType &&
    QUESTION_TYPES_WITH_DEFAULT_CHOICES.includes(specifiedQuestionType);

  if (
    !copyQuestionId &&
    seriesParameters.questions &&
    slideTypeApplicableForDefaultChoices
  ) {
    seriesParameters.questions = seriesParameters.questions.map(
      applyDefaultChoicesToQuestion,
    );
  }

  return core().series.post(seriesParameters);
}

function applyDefaultChoicesToQuestion(question: Partial<Question>) {
  return {
    ...question,
    choices: [
      { id: 1, label: 'Option A', position: 0, correct_answer: false },
      { id: 2, label: 'Option B', position: 1, correct_answer: false },
      { id: 3, label: 'Option C', position: 2, correct_answer: false },
    ],
  };
}

function getValidThemeId(themeId: string | undefined | null): number | null {
  if (!themeId) return null;

  try {
    return parseInt(themeId, 10);
  } catch (error) {
    return null;
  }
}

function getValidPace(pace: string | undefined | null): PaceMode | null {
  if (!pace) return null;
  return pace === 'audience' ? 'audience' : 'presenter';
}

function getValidQuestionType(
  questionType: string | undefined | null,
): QuestionType | undefined {
  return VALID_QUESTION_TYPES.find((q) => q === questionType) ?? undefined;
}

function getValidVisualisation(
  visualisation: string | undefined | null,
  questionType: string | undefined | null,
): QuestionSubType | null {
  if (!visualisation || !questionType) return null;

  const subType = getSubTypeFromVisualisationString(visualisation);

  if (VALID_VISUALISATIONS_BY_QUESTION_TYPE[questionType]?.includes(subType)) {
    return subType;
  }

  return null;
}

function getSubTypeFromVisualisationString(
  visualisation?: string,
): QuestionSubType | null {
  return VALID_VISUALISATIONS.find((v) => v === visualisation) ?? null;
}
