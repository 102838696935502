import { buildPresentationPath } from '@mentimeter/presentation-url-utils';
import type { Series } from '@mentimeter/http-clients';

export function getNewPresentationUrl({
  presentation,
  templateModal,
  importModal,
  quickCreate,
  copyQuestionId,
  questionType,
}: {
  presentation: Series;
  templateModal: string | null;
  importModal: string | null;
  quickCreate: string | null;
  copyQuestionId: string | null;
  questionType: string | null;
}) {
  const seriesPath = buildPresentationPath({
    seriesId: presentation.id,
    questionId: presentation.questions?.[0]?.id ?? '0',
    mode: 'edit',
  });
  const seriesUrl = new URL(`https://example.com${seriesPath}`);

  if (templateModal) seriesUrl.searchParams.set('template-modal', 'true');
  if (importModal) seriesUrl.searchParams.set('import-modal', 'true');
  if (quickCreate) seriesUrl.searchParams.set('quick-create', quickCreate);
  if (!copyQuestionId && !questionType)
    seriesUrl.searchParams.set('new', 'true');

  return `${seriesUrl.pathname}${seriesUrl.search}`;
}
